import React from "react"
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from "gatsby"

type Meta = {
  property: string
  content: string
}

type SeoProps = {
  title?: string
  description?: string
  metas?: Meta[]
}

const Seo = ({title, description, metas}: SeoProps) => {

  metas = metas || []

  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            lang
          }
        }
      }
    `
  )

  const metaTitle = title || data.site.siteMetadata.title
  const metaDescription = description || data.site.siteMetadata.description

  if (typeof window !== 'undefined') {
    metas.push(
      {
        property: "og:url",
        content: window.location.href,
      }
    )
  }

  return (
    <div>
      <Helmet
        htmlAttributes={
          {"lang": data.site.siteMetadata.lang}
        }
        title={metaTitle}
        meta={[
          {
            name: "description",
            content: metaDescription,
          },
          {
            property: "og:title",
            content: metaTitle,
          },
          {
            property: "og:description",
            content: metaDescription,
          },
          {
            property: "og:type",
            content: "website",
          },
          {
            property: "og:image",
            content: "https://actions-entreprises.shiftyourjob.org/share.png",
          },
          {
            name: "twitter:card",
            content: "summary",
          },
          {
            name: "twitter:site",
            content: "@Shiftyourjob",
          }
        ].concat(metas)}
      ></Helmet>
    </div>
  )
}

export default Seo
